import React, { FunctionComponent } from "react";

type Props = React.SVGProps<SVGElement>;

const Share: FunctionComponent<Props> = ({ className }) => (
  <svg viewBox="0 0 19 21" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.2 1.9a1.9 1.9 0 00-1.61 2.92 1.9 1.9 0 003.51-1 1.9 1.9 0 00-1.9-1.91zm-2.7 4.6A3.8 3.8 0 0019 3.82a3.8 3.8 0 10-7.46 1.05L6.5 7.8A3.8 3.8 0 000 10.5a3.8 3.8 0 006.5 2.69l5.04 2.95A3.84 3.84 0 0015.2 21c2.1 0 3.8-1.7 3.8-3.82a3.8 3.8 0 00-6.5-2.69l-5.04-2.95a3.84 3.84 0 000-2.08l5.04-2.95zm-7.09 3a.97.97 0 00.06.09A1.9 1.9 0 013.8 12.4a1.9 1.9 0 01-1.9-1.91 1.9 1.9 0 013.51-1zm8.1 6.82a.95.95 0 00.11-.2 1.9 1.9 0 013.48 1.06 1.9 1.9 0 11-3.6-.86z"
    />
  </svg>
);

export default Share;
