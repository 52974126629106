import React, { FunctionComponent } from "react";

type Props = React.SVGProps<SVGElement>;

const Book: FunctionComponent<Props> = ({ className }) => (
  <svg viewBox="0 0 21 19" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M.96 0A.95.95 0 000 .95V15.2c0 .52.43.95.96.95h6.68a1.91 1.91 0 011.9 1.9c0 .53.43.95.96.95s.96-.42.96-.95a1.9 1.9 0 011.9-1.9h6.68c.53 0 .96-.43.96-.95V.95a.95.95 0 00-.96-.95h-5.72a4.78 4.78 0 00-3.82 1.9A4.76 4.76 0 006.68 0H.96zm.95 1.9h4.77a2.87 2.87 0 012.86 2.85v10.01a3.83 3.83 0 00-1.9-.51H1.9V1.9zm12.4 0h4.78v12.35h-5.73c-.67 0-1.33.18-1.9.51V4.75a2.84 2.84 0 012.86-2.85z"
    />
  </svg>
);

export default Book;
