import React, { FunctionComponent } from "react";
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";

export type Props = {
  title: string;
  url: string;
  size: number;
  className?: string;
  buttonClassName?: string;
  iconClassName?: string;
};

const ShareBar: FunctionComponent<Props> = ({
  url,
  title,
  className,
  size,
  buttonClassName,
  iconClassName,
}) => (
  <div className={className}>
    <FacebookShareButton url={url} title={title} className={buttonClassName}>
      <FacebookIcon size={size} round className={iconClassName} />
    </FacebookShareButton>
    <TwitterShareButton
      url={url}
      title={title}
      className={buttonClassName}
      via="@ENEA_Consulting"
    >
      <TwitterIcon size={size} round className={iconClassName} />
    </TwitterShareButton>
    <LinkedinShareButton url={url} title={title} className={buttonClassName}>
      <LinkedinIcon size={size} round className={iconClassName} />
    </LinkedinShareButton>
    <WhatsappShareButton url={url} title={title} className={buttonClassName}>
      <WhatsappIcon size={size} round className={iconClassName} />
    </WhatsappShareButton>
    <EmailShareButton url={url} title={title} className={buttonClassName}>
      <EmailIcon size={size} round className={iconClassName} />
    </EmailShareButton>
  </div>
);

export default ShareBar;
