import React from "react";
import { PageProps, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useI18next } from "../utils/i18n";

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Offices from "../components/Offices";
import PublicationJumbotron from "../components/PublicationJumbotron";
import PublicationContent from "../components/PublicationContent";
import NewsletterCallToAction from "../components/NewsletterCallToAction";
import { ROUTES } from "../constants";
import download from "../utils/download";

export type Props = {};
type DataProps = {
  markdownRemark: {
    frontmatter: any;
    html: any;
  };
};

const PublicationTemplate: React.FC<PageProps<DataProps>> = ({
  data,
  location,
}) => {
  const { t } = useI18next();
  const { frontmatter, html } = data.markdownRemark;
  const {
    main_document_language,
    title,
    picture,
    main_document,
    date,
    complementary_documents,
    excerpt,
  } = frontmatter;

  const nbOfDocs = (complementary_documents || []).length + 1;

  const locationState = location.state as any;

  let backLink = ROUTES.publications.path;
  if (locationState?.prevPath) {
    backLink = locationState.prevPath;
  }

  return (
    <Layout backLink={backLink}>
      <SEO title={`${title} | Enea Consulting`} description={excerpt} />

      <article className="relative">
        <div className="sm:absolute inset-0 constrained pt-[45px] sm:pt-[85px] pb-[65px] sm:pb-0 bg-mine-shaft sm:bg-transparent">
          <a
            href={main_document.publicURL}
            className="block sm:sticky sm:top-7 z-10 max-w-[393px] sm:w-[30.703125%] xl:ml-[111px] sm:mr-8 lg:mr-[65px] xl:mr-[111px] sm:pb-7"
          >
            <GatsbyImage
              image={getImage(picture || main_document) as any}
              alt={title}
            />
          </a>
        </div>
        <div className="bg-mine-shaft sm:pt-[85px]">
          <div className="constrained">
            <div className="max-w-[604px] sm:ml-[35%] xl:ml-auto xl:mr-[61px]">
              <PublicationJumbotron
                title={title}
                dateTime={date}
                paragraph={`${main_document.extension.toUpperCase()}, ${t(
                  main_document_language || main_document.fields.language,
                )}. ${nbOfDocs} document${nbOfDocs > 1 ? "s" : ""}.`}
                onDownload={() => download(main_document, true)}
                pageUrl={location.href}
                onRead={() => download(main_document, false)}
              />
            </div>
          </div>
        </div>
        <div className="constrained pb-[145px]">
          <div className="max-w-[604px] sm:ml-[35%] xl:ml-auto xl:mr-[61px]">
            <PublicationContent
              className="relative"
              complementaries={complementary_documents?.map((doc: any) => {
                return {
                  title: doc.title,
                  tagline: `${doc.document.extension.toUpperCase()}, ${t(
                    doc.language || doc.document.fields.language,
                  )}`,
                  url: location.origin + doc.document.publicURL,
                  onDownload: () => download(doc.document, true),
                  onRead: () => download(doc.document, false),
                };
              })}
              innerHtml={html}
            />
          </div>
        </div>
      </article>

      <NewsletterCallToAction className="relative z-10 -mb-[250px] lg:-mb-[300px]" />

      <Offices />
    </Layout>
  );
};

export const query = graphql`
  query ($slug: String, $locale: String!) {
    markdownRemark(fields: { slug: { eq: $slug }, lang: { eq: $locale } }) {
      ...singlePublication
    }
  }
`;

export default PublicationTemplate;
