import React, { FunctionComponent, useState } from "react";
import Book from "../Icons/Book";
import Download from "../Icons/Download";
import Share from "../Icons/Share";
import ShareBar from "../ShareBar";

export type Props = {
  title: string;
  tagline: string;
  url: string;
  onRead?: () => void;
  onDownload?: () => void;
};

const PublicationDocMeta: FunctionComponent<Props> = ({
  onDownload,
  onRead,
  url,
  tagline,
  title,
}) => {
  const [shareOpen, setShareOpen] = useState<boolean>(false);

  return (
    <div className="py-[15px] md:px-[50px] border-b border-mercury">
      {shareOpen && (
        <ShareBar
          title={title}
          url={url}
          size={35}
          buttonClassName="ml-2"
          className="mb-1 text-right"
        />
      )}
      <div className="md:flex justify-between items-start ">
        <div className="max-w-[333px]">
          <h3 className="text-xl font-bold">{title}</h3>
          <p className="mt-[5px] text-relaxed text-gothic tracking-wide">
            {tagline}
          </p>
        </div>
        <div className="flex items-center pb-[9px] md:pb-0 pt-[15px] md:pt-[21px] text-oslo-grey">
          {!!onRead && (
            <button onClick={onRead}>
              <Book className="h-[19px]" />
            </button>
          )}
          {!!onDownload && (
            <button onClick={onDownload}>
              <Download className="h-[19px] ml-[25px]" />
            </button>
          )}
          <button onClick={() => setShareOpen((prevState) => !prevState)}>
            <Share className="h-[21px] ml-[25px]" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default PublicationDocMeta;
