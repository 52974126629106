import React, { FunctionComponent } from "react";
import PublicationDocMeta from "../PublicationDocMeta";
import { useI18next } from "../../utils/i18n";

type Complementary = {
  title: string;
  tagline: string;
  url: string;
  onRead?: () => void;
  onDownload?: () => void;
};

export type Props = {
  complementaries?: Array<Complementary>;
  // String content
  content?: string;
  className?: string;
  // From markdown content
  innerHtml?: string;
};

const PublicationContent: FunctionComponent<Props> = ({
  complementaries,
  content,
  className,
  innerHtml,
}) => {
  const { t } = useI18next();

  return (
    <div className={className}>
      {!!complementaries?.length && (
        <div className="pt-[85px]">
          <h3 className="pb-[25px] md:pl-[50px] border-b border-mercury text-12px font-bold tracking-wide uppercase">
            {t("publication.complementary")}
          </h3>
          {complementaries.map((complementary) => (
            <PublicationDocMeta
              key={complementary.title}
              title={complementary.title}
              tagline={complementary.tagline}
              url={complementary.url}
              onRead={complementary.onRead}
              onDownload={complementary.onDownload}
            />
          ))}
        </div>
      )}

      <div className="md:px-[50px]">
        {!!content && (
          <div className="mt-[85px] mb-[35px] whitespace-pre-line text-abbey text-lg">
            {content}
          </div>
        )}

        {!!innerHtml && (
          <div
            className="mt-[85px] mb-[35px] text-abbey prose"
            dangerouslySetInnerHTML={{ __html: innerHtml || "" }}
          />
        )}
      </div>
    </div>
  );
};

export default PublicationContent;
