import React, { FunctionComponent, useState } from "react";
import Button from "../Button";
import Book from "../Icons/Book";
import Download from "../Icons/Download";
import Share from "../Icons/Share";
import { useI18next } from "../../utils/i18n";
import ShareBar from "../ShareBar";

export type Props = {
  dateTime: string;
  title: string;
  paragraph: string;
  onRead: () => void;
  onDownload: () => void;
  pageUrl: string;
};

const PublicationJumbotron: FunctionComponent<Props> = ({
  dateTime,
  paragraph,
  title,
  onRead,
  onDownload,
  pageUrl,
}) => {
  const { language, t } = useI18next();
  const [shareOpen, setShareOpen] = useState<boolean>(false);

  return (
    <div className="bg-mine-shaft relative">
      <div className="lg:px-[50px]">
        <footer className="font-bold text-roboto text-12px tracking-wide uppercase">
          <time dateTime={dateTime} className="text-white">
            {new Date(dateTime).toLocaleDateString(language, {
              year: "numeric",
              month: "long",
            })}
          </time>
        </footer>
        <header>
          <h1 className="h1 mt-[35px] sm:mt-[45px] mb-[25px] font-bold tracking-wide text-white">
            {title}
          </h1>
        </header>
        <p className="whitespace-pre-line text-lg text-silver">{paragraph}</p>
        {shareOpen && (
          <ShareBar
            title={title}
            url={pageUrl}
            className="relative top-7"
            buttonClassName="mr-4"
            size={50}
          />
        )}
      </div>
      <div className="relative top-7 mb-7 sm:top-[45px] sm:mb-[45px] lg:flex mt-3.5 lg:mt-[31px] py-[18px] lg:py-0 lg:h-[95px] bg-neon-green rounded">
        <Button
          primary
          onClick={onRead}
          title={t("publication.report.read")}
          icon={<Book className="h-[19px] md:h-4 mr-[15px] text-oslo-grey" />}
          className="w-full lg:w-auto flex-1 flex items-center lg:justify-center lg:pl-12 text-18px whitespace-nowrap"
        />
        <Button
          primary
          onClick={onDownload}
          title={t("publication.report.download")}
          icon={
            <Download className="h-[19px] md:h-4 mr-[15px] text-oslo-grey" />
          }
          className="w-full lg:w-auto flex-1 flex items-center lg:justify-center text-18px whitespace-nowrap"
        />
        <Button
          primary
          onClick={() => setShareOpen((prevState) => !prevState)}
          title={t("publication.report.share")}
          icon={<Share className="h-[19px] md:h-4 mr-[15px] text-oslo-grey" />}
          className="w-full lg:w-auto flex-1 flex items-center lg:justify-center lg:pr-12 text-18px whitespace-nowrap"
        />
      </div>
    </div>
  );
};

export default PublicationJumbotron;
