import React, { FunctionComponent } from "react";
import { useI18next } from "../../utils/i18n";

import FormGroup from "../FormGroup";
import illustration from "../../images/NEWSLETTER.svg";

export type Props = {
  className?: string;
  disabled?: boolean;
};

const NewsletterCallToAction: FunctionComponent<Props> = ({
  className,
  disabled = true,
}) => {
  const { t } = useI18next();

  // Temporary disabled since there is no process to handle email subscriptions outside of netlify
  if (disabled) {
    return null;
  }

  return (
    <div className={`${className} constrained`}>
      <div className="md:flex md:justify-between py-[65px] md:py-80px px-7 md:px-[9%] rounded-card bg-black text-white overflow-hidden">
        <div className="md:w-[332px] lg:w-[393px] shrink-0 relative z-10">
          <h3 className="h2 mb-[25px] font-bold tracking-wide leading-9 md:leading-normal">
            {t("newsletter.title")}
          </h3>
          <p className="text-mercury leading-6">{t("newsletter.paragraph")}</p>
          <FormGroup
            className="mt-[35px]"
            inputProps={{
              placeholder: "email@company.com",
              name: "email",
              type: "email",
            }}
            buttonProps={{
              primary: true,
              type: "submit",
              title: t("newsletter.subscribe"),
              className: "px-[30px]",
            }}
          />
        </div>
        <div className="relative flex-1 min-h-[125px] mt-20 sm:mt-32 md:mt-0 md:ml-[9%]">
          <img
            src={illustration}
            alt=""
            className="absolute top-0 right-0 w-full scale-150 origin-center md:origin-top-right"
          />
        </div>
      </div>
    </div>
  );
};

export default NewsletterCallToAction;
