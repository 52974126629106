export default function download(file: any, download?: boolean) {
  const element = document.createElement("a");
  element.setAttribute("href", file.publicURL);
  if (download) {
    element.setAttribute("download", `${file.name}${file.ext}`);
  } else {
    element.setAttribute("target", "_blank");
  }

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}
