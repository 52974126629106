import React, { FunctionComponent } from "react";
import Input, { Props as InputProps } from "../Input";
import Button, { Props as ButtonProps } from "../Button";

export type Props = {
  inputProps: InputProps;
  buttonProps: ButtonProps;
  className?: string;
};

const FormGroup: FunctionComponent<Props> = ({
  inputProps,
  buttonProps,
  className,
}) => (
  <form
    className={`${className} sm:flex`}
    name="subscribe"
    data-netlify="true"
    method="POST"
    action="/subscribe-success"
  >
    <input type="hidden" name="form-name" value="subscribe" />
    <Input
      {...inputProps}
      className="w-full sm:rounded-tr-none sm:rounded-br-none border-none"
    />
    <Button
      {...buttonProps}
      className={`${buttonProps.className} mt-2 sm:mt-0 sm:rounded-tl-none sm:rounded-bl-none`}
    />
  </form>
);

export default FormGroup;
