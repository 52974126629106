import React, { FunctionComponent } from "react";

type Props = React.SVGProps<SVGElement>;

const Download: FunctionComponent<Props> = ({ className }) => (
  <svg viewBox="0 0 19 19" className={className}>
    <path
      fill="currentColor"
      d="M9.5 0c.52 0 .95.43.95.95v9.1l3.13-3.12a.95.95 0 011.34 1.34l-4.75 4.75a.95.95 0 01-1.34 0L4.08 8.27a.95.95 0 111.34-1.34l3.13 3.13V.95c0-.52.43-.95.95-.95zM.95 11.4c.52 0 .95.43.95.95v3.8a.95.95 0 00.95.95h13.3a.95.95 0 00.95-.95v-3.8a.95.95 0 111.9 0v3.8A2.85 2.85 0 0116.15 19H2.85A2.85 2.85 0 010 16.15v-3.8c0-.52.43-.95.95-.95z"
    />
  </svg>
);

export default Download;
