import React, { FunctionComponent } from "react";

export type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  title: string;
  primary?: boolean;
  inverted?: boolean;
  icon?: JSX.Element;
};

const Button: FunctionComponent<Props> = ({
  title,
  primary,
  inverted,
  className,
  icon,
  ...props
}) => {
  let variationClassName: string;
  if (primary) {
    variationClassName = "btn-primary";
  } else {
    variationClassName = inverted ? "btn-default-inverted" : "btn-default";
  }

  return (
    <button className={`btn ${variationClassName} ${className}`} {...props}>
      {icon} <span>{title}</span>
    </button>
  );
};

export default Button;
